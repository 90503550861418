<template>
    <div>
        <CCard class="p-4">
            <CRow>
                <CCol lg="6">
                    <h5>Bookings ({{ total }})</h5> 
                </CCol>
                <CCol lg="6">
                    <CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
                            :aria-expanded="showCollapse ? 'true' : 'false'"
                            aria-controls="collapse1"
                            @click="showCollapse = !showCollapse"
                            color="info"><i class="fa fa-search"></i>Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
                    <CButton 
                        v-if="config.getPermission('booking').create"
                        size="sm" style="margin-right: 10px" 
                        color="success" target="_blank " href="#/main/create_booking" 
                        class="float-lg-right"><i class="fa fa-plus"></i>Add Booking
                    </CButton>
                </CCol>
            </CRow>
            <CRow> 
                <CCol lg="12">
                    <CCollapse id="collapse1" :show="showCollapse" class="mt-2">
                        <CCard class="p-4">
                        <Search v-if="showCollapse" @depot-search-query="search"/>
                        </CCard>
                    </CCollapse> 
                </CCol>
            </CRow> 
            <hr>
            <CRow>
			
                <CCol lg="12">
                    <CTabs variant="pills" :active-tab="0">
                        <CTab title="All" @click.native="tabChanged($event, null)"></CTab>
						<CTab title="Approved" @click.native="tabChanged($event, 'approved')" v-if="company_type !== 'trucking'" ></CTab>
                        <CTab title="Under Review" @click.native="tabChanged($event, 'under review')"></CTab>
                        <CTab title="Dispatched" @click.native="tabChanged($event, 'dispatched')"></CTab>
                        <CTab title="Delivered" @click.native="tabChanged($event, 'delivered')"></CTab>
                        <CTab title="Completed" @click.native="tabChanged($event, 'completed')"></CTab>
                        <CTab title="Cancelled" @click.native="tabChanged($event, 'cancelled')"></CTab>
                        <CTab title="Declined" @click.native="tabChanged($event, 'declined')"></CTab>
                    </CTabs>
                </CCol>
            </CRow>
            <CRow>
                <CCol lg="12" class="table-responsive-sm">
                    <CDataTable 
				      		:loading="is_loading"
					      	:items="result"
					      	:fields="fields"
							hover
							outlined
							small
							fixed
							@pagination-change="paginationChange"
							:itemsPerPageSelect="{values: [20,50,100]}"
      						:itemsPerPage="limit"
					    >   
							<template #plate_no="{item}">
								<td style="padding: 10px; width: 100px; text-transform:uppercase;">
									{{ item.plate_no ? item.plate_no : '-' }}
									<br>
									<small>{{ item.vehicle_type ? item.vehicle_type : '' }}</small>
         						 </td>
					      	</template>
                            
							<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
									<br>
									<small>{{ item.client_billing_no ? item.client_billing_no : '' }}</small>
         						 </td>
					      	</template>
					      	<template #action="{item}">
						        <td style="padding: 5px; width: 100px">
						        	<CDropdown 
						                toggler-text="Select"  
										size="sm"
						                color="info"
						            >
                                        <CDropdownItem v-if="config.getPermission('booking').view" @click="viewModal(item)"><i class="fa fa-eye" > View </i></CDropdownItem>
                                        <CDropdownItem v-if="config.getPermission('booking').update 
                                            && hide_when(['dispatcher','finance staff','fleet staff','fleet manager','rw hr staff'], 
                                            ['delivered','under review','dispatched'].indexOf(item.status) > -1)" 
                                            @click="updateModal(item)"> <i class="fa fa-pencil-square-o"> 
                                            Update </i>
                                        </CDropdownItem>  
                                        <CDropdownItem @click="openApproveModal(item)" v-if="item.status == 'under review' && config.getPermission('booking').approve && company_type != 'trucking'"> <i class="fa fa-check-square-o"> Approve </i></CDropdownItem> 
                                        <CDropdownItem @click="openDispatchModal(item)" v-if="
                                            ((item.status == 'approved' || item.status == 'under review' ) &&
                                            config.getPermission('booking').dispatch && 
                                            company_type == 'trucking') ||
                                            ((item.status == 'approved') &&
                                            config.getPermission('booking').dispatch && 
                                            company_type != 'trucking')"
                                        > 
                                            <i class="fa fa-car"> Dispatch </i> 
                                        </CDropdownItem> 
                                        <CDropdownItem v-if="(item.status == 'completed' )" @click="bookAgain(item)"> <i class="fa fa-list-alt"> Book Again </i> </CDropdownItem> 
                                        <CDropdownItem @click="openDeliverModal(item)" v-if="item.status == 'dispatched' && config.getPermission('booking').deliver"> <i class="fa fa-paper-plane"> Deliver </i> </CDropdownItem> 
                                        <CDropdownItem @click="openCompleteModal(item)" v-if="item.status == 'delivered' && config.getPermission('booking').complete"> <i class="fa fa-list-alt"> Complete </i> </CDropdownItem> 
                                        <CDropdownItem v-if="config.getPermission('booking').cancel && item.status !== 'cancelled'" @click="cancelBooking(item)"> <i class="fa fa-window-close-o"> Cancel </i></CDropdownItem> 
                                        <CDropdownItem v-if="config.getPermission('booking').delete" @click="confirmDelete(item)"><i class="fa fa-trash"> Delete </i> </CDropdownItem> 


						            </CDropdown>
						        </td>
					      	</template>
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPage"
				          	:pages="Math.ceil(total / limit)"
				          	:activePage="currentPage"
				          	@update:activePage="updatePage($event)"
				        />
                </CCol>
            </Crow>
        </CCard>

        <ApproveModal  ref="approve_modal" @dismissModal="dismissModal"/>
        <DeliverModal  ref="deliver_modal" @dismissModal="dismissModal"/>
		<DispatchModal ref="dispatch_modal" @dismissModal="dismissModal"/>
        <CompleteModal ref="complete_modal" @dismissModal="dismissModal"/>
    </div>
</template>
<script>

import config from '../config.js';
import axios from '../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import moment from 'moment';
import '../style.css';
import CompleteModal from './status_modal/complete';
import ApproveModal from './status_modal/approve';
import DispatchModal from './status_modal/dispatch';
import DeliverModal from './status_modal/deliver';

import {Modal} from 'bootstrap';

import Loader from '../../loader.mixin.js';
export default {
    name : 'BookingsV2',
    mixins: [Loader],
    components: {Search, Datepicker, vSelect, CompleteModal,ApproveModal,DispatchModal,DeliverModal},
    computed: {
		fields() {
			const field = [...this.fields_holder];
			if(this.is_marvel()) {
				field[4] = {
					key: 'fuel', 
					label: 'Fuel'
				}
			}

			return field;
		}
	},
    data() {
        return {
            config,
            showCollapse: false,
            completeModal: false,
            approveModal: false,
            dispatchModal: false,
            deliverModal: false,
            selectedRow: null,
            company_type: JSON.parse(window.localStorage.getItem("user_data")).company.company_type.includes("Trucking") ? "trucking" : JSON.parse(window.localStorage.getItem("user_data")).company.company_type,
            filter: {
				booking_no: "",
	            booking_type_id: "",
				commodity_id: "",
	            customer_id: "",
	            asset_type: "",
	            origin_id: "",
				depot_id: "",
				vendor_id: "",
				client_ref_no: "",
	            delivery_date_to: new Date(),
	            delivery_date_from: moment(new Date()).subtract(1, 'month')._d, 
	            plate_no: ""
			},
            currentPage: 1, 
            limit: 20,
            is_loading: false,
            current_type: null,
            result: [],
            fields_holder: [
				{
					key: 'booking_no', 
					label: 'Booking No.'
				},
				{
					key: 'delivery_date_label', 
					label: 'Delivery Date',
					
				}, 
				{
					key: 'customer_name', 
					label: 'Customer',
					
					
				},
				{
					key: 'booking_type', 
					label: 'Type',
					_style: { width: '80%'}
				},
				{
					key: 'commodity', 
					label: 'Commodity',
					
				},
				{
					key: 'depot', 
					label: 'Depot'
				},  
					{
					key: 'origin', 
					label: 'Origin'
				},  
				// {
				// 	key: 'asset_type', 
				// 	label: 'Asset'
				// },
				{
					key: 'trucker', 
					label: 'Trucker'
				},  
				
				{
					key: 'plate_no', 
					label: 'Plate No.'
				},  
				
				{
					key: 'client_ref_no', 
					label: 'Client Ref No.'
				},
			
				
				
				{
					key: 'status', 
					label: 'Status'
				}, 
				{
					key: 'action',
					label: 'Action',
					_style: { width: '20px'}
				}
			],
            total: 0,
			modals: {
				complete: null,
				dispatch: null,
				approve:null,
				deliver: null
			}
        }
    },
    mounted() {
		this.$nextTick(() => {
			this.modals.complete = new Modal(document.getElementById('complete-modal'), {
				backdrop: 'static',
				keyboard: false
			});

			this.modals.dispatch = new Modal(document.getElementById('dispatch-modal'), {
				backdrop: 'static',
				keyboard: false
			});

			this.modals.approve = new Modal(document.getElementById('approve-modal'), {
				backdrop: 'static',
				keyboard: false
			});

			this.modals.deliver = new Modal(document.getElementById('deliver-modal'), {
				backdrop: 'static',
				keyboard: false
			});

			this.getData(null)
		})

        
    },
    methods: {
		dismissModal(type) {
			console.log(type)
			this.modals[type].hide();
			// switch (type) {
			// 	case 'dispatch':
			// 		this.$refs.dispatch_modal.details
			// 		break;
			
			// 	default:
			// 		break;
			// }
			this.getData(this.current_type)
		},
        openCompleteModal(item){
			this.$showLoading(true)
			this.$refs.complete_modal.details = null;
			axios.get(config.api_path+"/booking/"+item.id)
			.then(response=>{  
				// this.selectedRow =  response.data.data;
				this.$refs.complete_modal.details = response.data.data;
			})
			.finally(fin => {
				this.$showLoading(false)
				this.completeModal = true;

				

				this.modals.complete.show()
	    	
			})
	    	
	    },
        reloadData(){
			this.getData(this.current_type); 
			
		},
        tabChanged(ev, type) {
            console.log(ev, type)
            this.current_type = type;
            this.getData(type)
        },
        search(event){
	    	this.filter = event; 
			this.getData(this.current_type);
	    }, 
        paginationChange(ev){
			this.limit = ev;
			this.getData(this.current_type)
		},
        updatePage(ev){
			this.currentPage = ev;
			this.getData(this.current_type);
		},   
        getData(type = null) {  

	    	var booking_type_id = this.filter.booking_type_id;
	    	if(booking_type_id == "all" || booking_type_id == null){
	    		booking_type_id = ""
	    	}
			var commodity_id = this.filter.commodity_id;
	    	if(commodity_id == "All" || commodity_id == null){
	    		commodity_id = ""
	    	}
	    	var customer_id = this.filter.customer_id;
	    	if(customer_id == "All" || customer_id == null){
	    		customer_id = ""
	    	} 
	    	var origin_id = this.filter.origin_id;
	    	if(origin_id == "All" || origin_id == null){
	    		origin_id = ""
	    	} 
			var vendor_id = this.filter.vendor_id;
	    	if(vendor_id == "All" || vendor_id == null){
	    		vendor_id = ""
	    	} 
			var depot_id = this.filter.depot_id;
	    	if(depot_id == "All" || depot_id == null){
	    		depot_id = ""
	    	} 

	    	var delivery_date_from = this.filter.delivery_date_from;
	    	if(delivery_date_from != ""){
	    		delivery_date_from = moment(delivery_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    	}

	    	var delivery_date_to = this.filter.delivery_date_to;
	    	if(delivery_date_to != ""){
	    		delivery_date_to = moment(delivery_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
	    		delivery_date_to = delivery_date_to + 86399; 
	    	}

	    	if(this.filter.booking_no != ""){
	    		delivery_date_from = "";
	    		delivery_date_to = "";
	    	}

            this.is_loading = true;
            axios.get(`${config.api_path}/v2/booking`, {
                params: {
                    booking_no : this.filter.booking_no,
                    booking_type_id : booking_type_id,
                    commodity_id : commodity_id,
                    customer_id : customer_id,
                    asset_type : this.filter.asset_type,
                    origin_id : origin_id,
                    depot_id : depot_id,
                    delivery_date_to : delivery_date_to,
                    delivery_date_from : delivery_date_from,
                    vendor_id : vendor_id,
                    client_ref_no : this.filter.client_ref_no,
                    page : this.currentPage,
                    limit : this.limit,
                    plate_no : this.filter.plate_no,
                    status: type ? type : ''
                }
            }).then(response => {
                const {data, total} = response.data;
	    		this.result = data.map(item => {
					item.fuel = item.fuel ? item.fuel : '-';
					item.client_ref_no = item.client_ref_no ? this.$options.filters.truncate(item.client_ref_no):'-'; 
					if(item.is_subcon == 1)
						item.trucker = item.trucker;
					else
						item.trucker = item.company_name

					return item;
				})

                this.total = total;

                this.is_loading = false
	    		
	    	})
			.catch(err => {
				console.error(err)
				this.is_loading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.is_loading = false;	
			})
	    },
        getBadge (status) {
            return status === 'completed' ? 'success'
                : status === 'under review' ? 'dark'
                : status === 'approved' ? 'warning'
                : status === 'cancelled' ? 'secondary'
                : status === 'delivered' ? 'primary'
                : status === 'dispatched' ? 'info'
                : status === 'declined' ? 'danger' : 'primary'
        },

        viewModal(item){ 
	    	// this.$router.push('/main/view_booking/'+item.id) 
					window.open('/#/main/view_booking/'+item.id, '_blank');
	    }, 


	    cancelBooking(item){
	    	Swal.fire({
				icon:'warning',
				title: 'Are you sure you want to cancel this booking?', 
				showCancelButton: true,
				confirmButtonText: `Yes`, 
			}).then((result) => { 
				if (result.isConfirmed) {
					Swal.fire({
					  title: 'Cancellation Reason',
					  input: 'text',
					  inputAttributes: {
					    autocapitalize: 'off',
					    required: true
					  },
					  showCancelButton: true,
					  confirmButtonText: 'Save',
					  showLoaderOnConfirm: true,
					  preConfirm: (cancellation_reason) => {
					  	return axios.put(config.api_path+'/booking/'+item.id, {
					  		status: "cancelled",
							user_id: config.getUserDetail('id'),
							status_update: true,
							cancellation_reason
					  	})
				    	.then(response => {
				    		
				    		if (response.status == "success") {
					          throw new Error(response.statusText)
					        }
					        return response;
				    	}) 
				    	.catch(error => {
					        Swal.showValidationMessage(
					          `Request failed: ${error}`
					        )
					      }) 
					      
					  },
					  allowOutsideClick: () => !Swal.isLoading()
					}).then((result) => {
					  if (result.isConfirmed) {
					  	this.getData(); 
					    Swal.fire({
							title: 'Success!',
							text: "Booking successfully cancelled.",
							icon: 'success', 
						})
					  }
					})
					
				}
			})  
	    },

	    confirmDelete(item){
	    	Swal.fire({
				icon:'warning',
				title: 'Are you sure you want to delete '+item.booking_no+"?", 
				showCancelButton: true,
				confirmButtonText: `Yes, delete`, 
				reverseButtons: true,
				confirmButtonColor:'red'
			}).then((result) => { 
				if (result.isConfirmed) {
					
					axios.delete(config.api_path+'/booking/'+item.id)
					.then(response=>{
						Swal.fire({
							title: 'Success!',
							text: "Booking "+item.booking_no+" successfully deleted.",
							icon: 'success', 
						})
						this.getData(this.current_type); 
						
					})
					
				}
			})  
	    },

	    openApproveModal(item){
	    	// this.$refs.approve_modal.details = null;
			// this.$showLoading(true)
	    	// axios.get(`${config.api_path}/v2/booking/${item.id}/approve`)
			// .then(response=>{  
			// 	// this.selectedRow =  response.data.data;
			// 	this.$refs.approve_modal.details = {...response.data.data};
			// })
			// .finally(fin => {
			// 	this.$showLoading(false)
			// 	this.approveModal = true;
			// 	this.modals.approve.show()
			// })
			this.$refs.approve_modal.details = {
				id : item.id,
				remarks: item.remarks
			}

			this.modals.approve.show()
	    },

	    openDispatchModal(item){
			this.$showLoading(true)
			this.$refs.dispatch_modal.details = null;
	    	axios.get(`${config.api_path}/v2/booking/${item.id}/dispatch`)
			.then(response=>{  
				// this.selectedRow =  response.data.data;
				this.$refs.dispatch_modal.details = {...response.data.data};
			})
			.finally(fin => {
				this.$showLoading(false)
				this.dispatchModal = true;

				

				this.modals.dispatch.show()
	    	
			})
	    },

	    openDeliverModal(item){
			this.$showLoading(true)
			this.$refs.deliver_modal.details = null;
	    	axios.get(`${config.api_path}/v2/booking/${item.id}/deliver`)
			.then(response=>{  
				// this.selectedRow =  response.data.data;
				this.$refs.deliver_modal.details = {...response.data.data};
			})
			.finally(fin => {
				this.$showLoading(false)
				this.deliverModal = true;
				this.modals.deliver.show()
			})
	    },

        updateModal(item){
	    	// this.$router.push('/main/update_booking/'+item.id)
			console.log(item)
			if(item.status?.toLowerCase() == 'completed') {
				if(item.client_billing_status?.toLowerCase() == 'billed') {
					Swal.fire({
						title: 'Update not allowed!',
						html: "This waybill is already <b>BILLED</b> in client billing.",
						icon: 'warning', 
					});

					return;
				}
				
			}
			
			window.open('/#/main/update_booking/'+item.id, '_blank');
	    },

        bookAgain(item) {
			window.open(`/#/main/create_booking/${item.id}/1`, '_blank');
		},
	   
    }
}
</script>